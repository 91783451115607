import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import ProfileProvider from "../../providers/ProfileProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { getJWToken } from '../../helpers/utils';
import ConfirmationJSONObject from '../../static/configs/moloConfirmationList.json'

//Image imports
import moloSalesImg from "assets/images/app_icons/molo-sales.png";
import moloAdminImg from "assets/images/app_icons/molo-admin.png";
import ESSImg from "assets/images/app_icons/ESS.png";
import MTNIlulaImg from "assets/images/app_icons/mtn-ilula.png";
import VodacomImg from "assets/images/app_icons/vodacom-c3d.png";
import googleMapsImg from "assets/images/app_icons/google-maps.png";
import itSupport from "assets/images/app_icons/it-support.png";
import email from "assets/images/app_icons/email.png";
import WarrantySolutionsImg from "assets/images/app_icons/warranty_solutions.png";
import qContactImg from "assets/images/app_icons/q-contact.png";
import qContactUKimg from "assets/images/app_icons/q-contactUK.png";
import qContactUSigm from "assets/images/app_icons/q-contactUS.png";
import avtImg from "assets/images/app_icons/avt.png";
import rewardscoLearningImg from "assets/images/app_icons/Rewardsco_Learning.png";
import autoAllianceWiki from "assets/images/app_icons/auto_alliance_wiki.png";
import indraEnergyWikiImg from "assets/images/app_icons/indra_energy_wiki.png";
import connexAI from "assets/images/app_icons/connex_ai.png";
import noetica from "assets/images/app_icons/noetica.png";
import five_9 from "assets/images/app_icons/five_9.png";
import inlineCrmImg from "assets/images/app_icons/inline_crm.png";
import callToolsImg from "assets/images/app_icons/call_tools.png";
import tpv_360 from "assets/images/app_icons/tpv-360.png";
import intellicell from "assets/images/app_icons/intellicell.png";
import simunye_debt from "assets/images/app_icons/simunye_debt.png";

// import spa from "assets/images/app_icons/spa.png";
// import indraEnergy from "assets/images/app_icons/indra_energy.png";
// import onestreamImg from "assets/images/app_icons/onestream-logo.png";
// import tpvImg from "assets/images/app_icons/tpv.png";

function AppCenter() {
  const [moloSalesUrl, setMoloSalesUrl] = useState(null);
  const validationStatus = localStorage.getItem("confirmationValid") === "true" ? true : false;

  const appLinks = [
    {id: 'molo_admin', alt:'Molo Admin', link: process.env.REACT_APP_MOLO_ADMIN, image: moloAdminImg},
    {id: 'ess', alt:'ESS', link: process.env.REACT_APP_ESS, image: ESSImg},
    {id: 'mtn_ilula', alt:'MTN Ilula', link: process.env.REACT_APP_ILULA, image: MTNIlulaImg},
    {id: 'vodacom_c3d', alt:'Vodacom C3D', link: process.env.REACT_APP_VODACOM, image: VodacomImg},
    {id: 'google_maps', alt:'Google Maps', link: process.env.REACT_APP_MAPS, image: googleMapsImg},
    {id: 'it_support', alt:'IT Support', link: process.env.REACT_APP_SERVICEDESK, image: itSupport},
    {id: 'email', alt:'Email', link: process.env.REACT_APP_OUTLOOK, image: email},
    {id: 'warranty_solutions',  alt:'Warranty Solutions', link: process.env.REACT_APP_WARRANTY_SOLUTIONS, image: WarrantySolutionsImg},
    {id: 'q_contact', alt:'Qcontact', link: process.env.REACT_APP_Q_CONTACT, image: qContactImg},
    {id: 'q_contact_uk', alt:'Qcontact_UK', link: process.env.REACT_APP_Q_CONTACT_UK, image: qContactUKimg},
    {id: 'q_contact_us', alt:'Qcontact_US', link: process.env.REACT_APP_Q_CONTACT_US, image: qContactUSigm},
    {id: 'avt', alt:'AVT', link: process.env.REACT_APP_AVT, image: avtImg},
    {id: 'rewardsco_learning', alt:'Rewardsco Learning', link: process.env.REACT_APP_REWARDSCO_LEARNING, image: rewardscoLearningImg},
    {id: 'inline_crm', link: process.env.REACT_APP_INLINE_CRM, image: inlineCrmImg},
    {id: 'call_tools', link: process.env.REACT_APP_CALLTOOLS, image: callToolsImg},
    {id: 'auto_alliance_wiki', alt:'Auto Alliance Wiki', link: process.env.REACT_APP_AUTO_ALLIANCE_WIKI, image: autoAllianceWiki},
    {id: 'indra_energy_wiki', alt:'Indra Energy Wiki', link: process.env.REACT_APP_INDRA_ENERGY_WIKI, image: indraEnergyWikiImg},
    {id: 'connex_ai', alt:'Connex AI', link: process.env.REACT_APP_CONNEX_AI, image: connexAI},
    {id: 'noetica', alt:'Noetica', link: process.env.REACT_APP_NOETICA, image: noetica},
    {id: 'five9', alt:'Five9', link: process.env.REACT_APP_FIVE_NINE, image: five_9},
    {id: 'tvp360', alt:'TVP360', link: process.env.REACT_APP_TPV_THREE_HUNDRED_SIXTY, image: tpv_360},
    {id: 'intellicell', alt:'Intellicell', link: process.env.REACT_APP_INTELLICELL, image: intellicell},
    {id: 'simunye_debt', alt:'simunye_debt', link: process.env.REACT_APP_SIMUNYE_DEBT_SETTLEMENT, image: simunye_debt}
    
    // {id: 'spa', alt:'Spa', link: process.env.REACT_APP_SPA, image: spa},
    // {id: 'indra_energy', alt:'Indra Energy', link: process.env.REACT_APP_INDRA_ENERGY, image: indraEnergy},
    // {id: 'tpv', alt:'TPV', link: process.env.REACT_APP_TPV, image: tpvImg},
    // {id: 'onestream', alt:'Onestream', link: process.env.REACT_APP_ONESTREAM, image: onestreamImg},
    // {id: 'molo_phanda', link: '', image: image3},
    // {id: 'mylo', link: process.env.REACT_APP_MYLO, image: image5},
    // {id: 'nebula', link: '', image: image6},
    // {id: 'qa', link: process.env.REACT_APP_CALLBI, image: image11},
    // {id: 'debt_mate', link: process.env.REACT_APP_DEBT_MATE, image: image22},
    // {id: 'tih', link: process.env.REACT_APP_TELESURE, image: image24},
    // {id: 'chooseleads', link: process.env.REACT_APP_CHOOSELEADS, image: image27},
    // {id: 'afeusa', link: process.env.REACT_APP_AFEUSA, image: image28},
    // {id: 'melon_mobile', link: process.env.REACT_APP_MELON_MOBILE, image: image29},
  ]

  async function fetchData() {
    let moloSalesUrl = process.env.REACT_APP_MOLO_SALES;
    const userPrincipalName = localStorage.getItem('userPrincipalName');
    const profile = await ProfileProvider.getProfile(userPrincipalName);
    const { DiallerUserName, UserName } = profile;

    if (DiallerUserName) {
      const jwtToken = getJWToken({
        UserName: UserName || null,
        DiallerUserName,
        time: new Date(),
      });
      moloSalesUrl += `?referrer=RP&tid=${jwtToken}`;
      setMoloSalesUrl(moloSalesUrl);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={2}>
        <SuiBox p={2}>
          <Grid wrap='wrap' container columnSpacing={6} rowSpacing={6}>
            <Grid id='moloSales' item xs={12} lg={3} >
              <SuiBox
                height="100%"
                display="grid"
                justifyContent="center"
                alignItems="center"
                borderRadius="lg"
                variant="gradient"
                sx={{ border: '2px solid #00000012' }}
              >
                <a href={moloSalesUrl} onClick={(e) => {
                  if (!moloSalesUrl) {
                    e.preventDefault()
                    return alert("No Molo URL Set")
                  }
                  
                  if (!validationStatus) {
                      e.preventDefault();
                      return alert("Please Verify Manager and Campaign Details on User Profile page to continue")
                  }
                }}>
                  <SuiBox component="img" src={moloSalesImg} alt="Molo Sales" width="100%" pt={3} />
                </a>
              </SuiBox>
            </Grid>
            {appLinks.length > 0 && appLinks.map((app) => (
              <Grid item xs={12} lg={3} >
                <SuiBox
                  height="100%"
                  display="grid"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="lg"
                  variant="gradient"
                  sx={{ border: '2px solid #00000012' }}
                >
                  <a href={app.link} style={{textAlign: 'center'}}>
                    <SuiBox component="img" src={app.image} alt={app.alt || 'app'} width="90%" py={3} />
                  </a>
                </SuiBox>
              </Grid>
            ))}
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AppCenter;
